import './ringoffire.css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shuffle } from 'fast-shuffle';
import RingOfFireCard from './RingOfFireCard/RingOfFireCard';
import { useEffect } from 'react';


export function RingOfFireView({ onFinished, players, playerIndex, getRandomNumber, getRandomPlayer, getOtherPlayer, setNextPlayer, setPlayerModalOpen }) {
    const [incorrectCardIndex, setIncorrectCardIndex] = useState(0);
    const cardValues = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "10", "Knekt", "Dam", "Kung"];
    const cardColor = ["hjarter", "spader", "klover", "ruter"];

    const [currentColor, setCurrentColor] = useState(cardColor[0]);
    const [currentValue, setCurrentValue] = useState(cardValues[0])
    const [flippedCardIndex, setFlippedCardIndex] = useState(-1);
    /**
     * Resets cards for the next player.
     */
    function nextPlayer() {
        resetAllCards()
        setCurrentColor(shuffle([...cardColor])[0]);
        setCurrentValue(shuffle([...cardValues])[0]);
        setIncorrectCardIndex(Math.floor(Math.random() * 5))
        setNextPlayer();
    }

    function resetAllCards() {
        setFlippedCardIndex(-1)
    }

    useEffect((players)=> {
        if (!players) {
            setPlayerModalOpen(true)
        }
    },[])

    function flipCard(index) {
        if (flippedCardIndex === index) {
            nextPlayer();
        } else {
            setFlippedCardIndex(index)
        }
    }

    let cardElements = [];

    for (let i = 0; i < 3; i++) {
        cardElements.push((
            <RingOfFireCard key={i} isFlipped={flippedCardIndex === i} isBadCard={i === incorrectCardIndex} currentValue={currentValue} currentColor={currentColor} flipCard={() => flipCard(i)} />
        ));
    }

    const isAnyCardFlipped = flippedCardIndex > -1;

    return (<>
        <div id="ring-of-fire-container">
            <p id="player">{players.length != 0 ? players[playerIndex] : "Lägg till spelare"}</p>
            <div id="cards-container">
                {cardElements}    
                <button className='primary' disabled={!isAnyCardFlipped} id="ring-of-fire-button" onClick={() => nextPlayer()}>Nästa</button>
            </div>
        </div>
    </>
    )
}