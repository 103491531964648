import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export function PlayerModal({players, setPlayers, setPlayerModalOpen }) {
//För att förhindra rerenders i bakgrunden delas faktiska spelare och playermodalen upp
const [localPlayers, setLocalPlayers] = useState([])
const arePlayersValid = localPlayers.filter(playerName => playerName.trim()).length >= 3;
const [isTooFewPlayers, setTooFewPlayers] = useState(false)
  // handle input change
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    setLocalPlayers(
      localPlayers.map((oldPlayerName, playerIndex) => {
        if (playerIndex === index) {
          return value;
        } else {
          return oldPlayerName;
        }
      })
    );
  };


  useEffect(()=>{
    setLocalPlayers(players);
  },[players])


  function closePlayerModal() {
    if(arePlayersValid) {
      setPlayers(localPlayers);
      setPlayerModalOpen(false);
    } else {
      setTooFewPlayers(true)
    }
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...localPlayers];
    list.splice(index, 1);
    setLocalPlayers(
      localPlayers.filter((playerName, playerIndex) => {
        if (playerIndex !== index) {
          return true;
        } else {
          return false;
        }
      }))
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setTooFewPlayers(false);
    setLocalPlayers([...localPlayers, ""]);
    const playerModal = document.getElementById('player-modal');
    playerModal.scrollTop = playerModal.scrollHeight - playerModal.clientHeight;
  };

  return (<>
    <div id="player-modal">
      <h2 id="player-modal-text">Vilka spelar?</h2>
      {isTooFewPlayers ? <p id="too-few-players" style={{fontSize: "16px"}}>Lägg till fler spelare!</p> : null}
      <div id="players-container">
      {localPlayers.map((playerName, i) => {
        return (<>
          <div className="player-modal-list" key={i}>
            <input
              name="name"
              autoFocus
              placeholder="Spelarens namn"
              value={playerName}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleAddClick();
                }
            }}
              onChange={e => handleInputChange(e, i)}
            />
            <div className="btn-box">
              {localPlayers.length > 3 && <button className="remove-player" onClick={() => handleRemoveClick(i)}><FontAwesomeIcon icon="trash-alt" /></button>}                </div>
          </div>
        </>
        );
      })}
      </div>
      <div id="player-actions">
      {localPlayers.length - 1 < 12 && <button className="add-player" onClick={handleAddClick}>Lägg till spelare +</button>}
      <button id="player-modal-play" disabled={!arePlayersValid} className="primary" onClick={() => {
        closePlayerModal()
      }
      }>Spela</button>

      </div>
    </div>
    <div onClick={() => {
      closePlayerModal();
    }} id="overlay"></div>
  </>
  );
}