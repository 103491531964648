import classNames from "classnames"
import { useRef } from "react";
import { useEffect } from "react/cjs/react.production.min";

//  flipCard är en funktion, men vi vet inte exakt vad den gör eftersom vår parent skickar in den
export default function RingOfFireCard({ currentColor, currentValue, flipCard, isFlipped, isBadCard }) {
    const itemContainer = useRef()
    const oldLeft = useRef()
    const unSubContent = useRef()



    const dares = [
        ["A", "Vattenfall"],
        ["2", "Choose"],
        ["3", "Me"],
        ["4", "Girls"],
        ["5", "Thumb"],
        ["6", "Dicks"],
        ["7", "Heaven"],
        ["8", "Mate"],
        ["9", "Rhyme"],
        ["10", "Categories"],
        ["Knekt", "make a rule"],
        ["Dam", "questions"],
        ["Kung", "Pour in middle or drink entire"],
    ];

    function getDare(value) {
        for (let dare of dares) {
            if (dare[0] === value) {
                return dare[1]
            }
        }
    }

    return (
        <div ref={
            (element) => itemContainer.current = element
        } className={classNames({
            flipped: isFlipped,
            "card-holder": true
        })}>
            <div ref={
                (element) => {
                    if (!element) return
                    clearInterval(unSubContent.current)
                    unSubContent.current = setInterval(() => {
                        element.style.left = oldLeft.current
                        const left = itemContainer.current?.getBoundingClientRect().left - itemContainer.current?.parentElement.getBoundingClientRect().left;
                        element.style.left = isFlipped ? itemContainer.current?.parentElement.offsetWidth / 2 - element.offsetWidth / 2 + 'px' : left + 'px';
                    }, 50)
                }
            } onClick={() => flipCard()} className={
                classNames({
                    card: true,
                    flipped: !isFlipped
                })
            }>
                <p><img className="color-icon" src={currentColor + ".png"} />{isBadCard ? "You suck" : currentValue}</p>
                <p>{isBadCard ? "Drink mtrfkr u suck" : getDare(currentValue)}</p>
            </div>
        </div>

    )
}