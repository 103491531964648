import './questiongame.css';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRandomPlayer } from '../../utils.js';
import { db, getQuestions } from '../../App';
import fallbackQuestions from './questionsCache.json'
import { doc, getDoc } from 'firebase/firestore';
import { shuffle } from 'fast-shuffle';

//GÖR SÅ MED ALLA ^^^^^^^^^^^ utils

export function QuestionGameView({ onFinished, players, getRandomNumber, getOtherPlayer, setPlayerIndex, playerIndex, setPlayerModalOpen, setNextPlayer }) {
    const [currentPlayer, setCurrentPlayer] = useState(getRandomPlayer(players));
    const [questions, setQuestions] = useState([]);
    const [isGettingQuestions, setIsGettingQuestions] = useState(true);

    async function getQuestions(db) {
        try {
            const questionsCol = doc(db, 'Games', "Question Game");
            const questionSnapshot = await getDoc(questionsCol);
            const questionList = shuffle(questionSnapshot.data().questions);
            setIsGettingQuestions(false)
            setQuestions(questionList);
        } catch (e) {
            console.error(e);
            setQuestions(shuffle(fallbackQuestions));
        }

    }

    useEffect(() => {
        getQuestions(db);
    }, [])

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);

    function setNextQuestion() {
        setNextPlayer()
        if (questions.length > currentQuestionIndex) {
            setCurrentQuestionIndex(currentQuestionIndex => currentQuestionIndex + 1);
        } else {
            //  Inga frågor kvar
            onFinished();
        };
    }

    if (!players) {
        return (
            <div id="question-game-container">
                <div id="header">
                    <h1>Frågeleken</h1>
                </div>
                <div id="question">
                    <p>Klicka på starta för att lägga till spelare</p>
                </div>
                <button className="primary" onClick={() => setNextQuestion()}>Starta</button>
            </div>
        )
    }

    const hasCurrentQuestion = !!questions[currentQuestionIndex] && !isGettingQuestions;

    return (
        <div id="question-game-container">
            <div id="header">
                <h1>Frågeleken</h1>
            </div>
            <div id="question">
                <p style={{transition:"opacity 0.5s", opacity: isGettingQuestions ? 0 : 1 }}>{hasCurrentQuestion ? questions[currentQuestionIndex].replace("__player__", players[playerIndex]).replace("__player1__", getOtherPlayer(1)).replace("__player2__", getOtherPlayer(2)) : "Klicka på knappen för att starta"}</p>
            </div>
            <button className="primary" onClick={() => setNextQuestion()}>Nästa</button>
        </div>
    )
}