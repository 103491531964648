import './App.css';
import { useEffect, useState, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route, Navigate, useLocation, Redirect, useHistory } from 'react-router';
import { QuestionGameView } from './Games/QuestionGame/QuestionGame';
import { PlayerModal } from './PlayerModal';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectGameView } from './SelectGameView';
import { PointingGameView } from './Games/PointingGame/PointingGame';
import { RingOfFireView } from './Games/RingOfFire/RingOfFire';
import AdminView from './AdminView';

import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc, collection, getDocs, Firestore, setDoc, onSnapshot } from "firebase/firestore";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { SwitchingCardView } from './Games/RatherGame';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUtD6A0brXtBiMEh-ifSf3pMlFyra-YMY",
  authDomain: "mixio-00000.firebaseapp.com",
  databaseURL: "https://mixio-00000-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mixio-00000",
  storageBucket: "mixio-00000.appspot.com",
  messagingSenderId: "1097849042233",
  appId: "1:1097849042233:web:0421497cf2e4b879f364e8",
  measurementId: "G-VC2CG8X1KX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const provider = new GoogleAuthProvider();



library.add(faTrashAlt, faCog, faSignOutAlt);
function App() {
  const games = [
    {
        name: "Frågeleken",
        slug: "question-game",
        icon: "hand-mixio.png"
    },
    {
        name: "Chance of Fire",
        slug: "chance-of-fire",
        icon: "rock-mixio.png"
    },
    {
        name: "Pekleken",
        slug: "pointing-game",
        icon: "point-mixio.png"
    },
    {
        name: "Rather",
        slug: "rather",
        icon: "point-mixio.png"
    }
  ];

  const [players, setPlayers] = useState(["", "", ""]);
  const [playerIndex, setPlayerIndex] = useState(0);
  const [isPlayerModalOpen, setPlayerModalOpen] = useState(false);
  const [isFinishOpen, setFinishOpen] = useState(false);
  const history = useHistory();

function onFinished() {
  setFinishOpen(true);
  history.push("/select-game");
}

function getOtherPlayer(offset) {
  return players[((playerIndex + offset) % players.length + players.length) % players.length];
  }

  const randomNumber = useRef(0);
function getRandomNumber(array) {
  randomNumber.current = Math.round(Math.random()*(array.length - 1));
  return randomNumber.current;
}


function setNextPlayer() {
  if (!players[0]) {
    setPlayerModalOpen(true);
  } else {
  setPlayerIndex(()=> {
    if (playerIndex === players.length - 1) {
        return 0;
    } else {
        return playerIndex + 1;
    }})
  }
}


useEffect(()=>{
  !isPlayerModalOpen && setPlayers(players => players.map( playerName => playerName.trim()).filter(playerName => playerName)) // Ta bort alla mellanslag före och efter och därefter filtrera bort alla tomma
},[isPlayerModalOpen])


  return (
    <>
    <img src="mixio-logo-stacked.png" id="logo" alt=""></img>
        
        <Switch>
            <Route path="/chance-of-fire" render={()=>  <RingOfFireView players={players} getRandomNumber={getRandomNumber} getOtherPlayer={getOtherPlayer} onFinished={onFinished} setPlayerIndex={setPlayerIndex} playerIndex={playerIndex} setPlayerModalOpen={setPlayerModalOpen} setNextPlayer={setNextPlayer}/>} />
            <Route path="/question-game" render={()=> <QuestionGameView players={players} getRandomNumber={getRandomNumber} getOtherPlayer={getOtherPlayer} onFinished={onFinished} setPlayerIndex={setPlayerIndex} playerIndex={playerIndex} setPlayerModalOpen={setPlayerModalOpen} setNextPlayer={setNextPlayer}/>} />
            <Route path="/pointing-game" render={()=> <PointingGameView getRandomNumber={getRandomNumber}  onFinished={onFinished} />} />
            <Route path="/select-game" render={()=> <SelectGameView players={ players } games={ games } setPlayerModalOpen={setPlayerModalOpen}/>}  />
            <Route path="/home" component={(null)} />
            <Route path="/admin" render={()=> <AdminView />} />
            <Route path="/rather" render={()=> <SwitchingCardView players={players} setNextPlayer={setNextPlayer} playerIndex={playerIndex} setPlayerModalOpen={setPlayerModalOpen} onFinished={onFinished} />} />
            <Route path="/" render={() => <Redirect to="/select-game" />} />
        </Switch>
   
    <button id="player-modal-button" onClick={()=>setPlayerModalOpen(true)}><FontAwesomeIcon icon="cog" /></button>
    <button id="leave-game-button" onClick={()=>history.push('/select-game')}>X</button>
    {isPlayerModalOpen ? <PlayerModal players={players} setPlayers={setPlayers} setPlayerModalOpen={setPlayerModalOpen} />: null }
    {isFinishOpen ? /*<FinishGameModal />*/ <>
    <div id="finish-game-view">
    <h1 id="game-over">Spelet är slut!</h1>
    <button className='primary' onClick={() => {
      setFinishOpen(false)
      history.push("/select-game")
    }}>Spela fler spel</button>
    </div>
    </>: null }
      </>
  );
}

export default App;


