import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { doc, Firestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { setDoc, getDoc } from "firebase/firestore";
import { db } from "./App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export default function AdminView() {
    const auth = getAuth();
    const [user, setUser] = useState(getUserFromLocalStorage());
    const [questionsFromDb, setQuestionsFromDb] = useState([]);
    const [questionsFromUser, setQuestionsFromUser] = useState([]);


    useEffect(() => {
        const unsubQuestions = onSnapshot(
            doc(db, 'Games', "Question Game"),
            (doc) => { 
                setQuestionsFromDb(doc.data().questions)
            });
        return unsubQuestions
    }, [])

    useEffect(()=> {
        setQuestionsFromUser(questionsFromDb);
    },[questionsFromDb])


    async function uploadQuestions(questionsFromUser) {
        const gameDatabase = doc(db, 'Games', "Question Game");
        const gameDatabaseSnapshot = await setDoc(gameDatabase, { questions: questionsFromUser });
    }


    const hasChanged = JSON.stringify(questionsFromUser) !== JSON.stringify(questionsFromDb);

    const handleInputChange = (e, index) => {
        const { value } = e.target;
        setQuestionsFromUser(questionsFromUser => 
            questionsFromUser.map((oldQuestion, question) => {
                if (question === index) {
                    return value;
                } else {
                    return oldQuestion;
                }
            })
        );
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...questionsFromUser];
        list.splice(index, 1);
        setQuestionsFromUser(questionsFromUser =>
            questionsFromUser.filter((question, questionIndex) => {
                if (questionIndex !== index) {
                    return true;
                } else {
                    return false;
                }
            }))
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setQuestionsFromUser([...questionsFromUser, ""]);
    };


    function getUserFromLocalStorage() {
        const fallbackUser = { username: "Kompis" };
        try {
            const localUser = JSON.parse(localStorage.getItem('user'))
            return localUser || fallbackUser;
        } catch(e){
            console.error(e);
            return fallbackUser
        }
    }

    function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((success) => {

                let userSuccess = success.user;

                function checkIfAdmin(userSuccess) {
                    switch(userSuccess.email) {
                        case "viktor@helasverigesamlas.se":
                          return true;
                          break;
                        case "viktor.klasson@hotmail.com":
                          return true;
                          break;
                          case "lifeazwilma@gmail.com":
                          return true;
                          break;
                        default:
                          return false
                      }
                }

                const userData = {
                    username: userSuccess.displayName,
                    email: userSuccess.email,
                    uid: userSuccess.uid,
                    img: userSuccess.photoURL,
                    admin: checkIfAdmin(userSuccess)
                }
                localStorage.setItem('user', JSON.stringify(userData))
                setUser(userData);
            })
            .catch(err => err.message)
    }

    if (!user.email || !user.admin) {
        return (
            <div style={{ textAlign: "center" }}>
                <h1 >Adminpanel</h1>
                <h2 >Välkommen, Polaren! Logga in!</h2>
                <button onClick={() => signInWithGoogle()}>Logga in</button>
            </div>
        )
    }

    return (<>
        <div style={{display: "flex", flexDirection: "column", textAlign: "center", overflow: "auto" }}>
            <h1>Adminpanel</h1>
            <h2 >Välkommen, {user.username}!</h2>
            { hasChanged && <>
            <p style={{position: "fixed",top: 0, left: 0, fontSize: "20px"}}>Du har fan inte sparat än broder.</p>
            </>}
            <div style={{display: "flex", flexDirection: "column", flexGrow: "1", height: "50vh", overflow: "scroll"}}>
            {questionsFromUser.map((question, i) => {
                return (<>
                    <div style={{display: "flex", width: "70%", margin: "auto"}} className="player-modal-list">
                        <input
                            style={{flexGrow: 1}}
                            name="Question"
                            autoFocus={i === 0}
                            placeholder="Ny fråga"
                            value={question}
                            onChange={e => handleInputChange(e, i)}
                        />

                        <div className="btn-box">
                            <button className="remove-player" onClick={() => handleRemoveClick(i)}><FontAwesomeIcon icon="trash-alt" /></button>
                        </div>
                    </div>
                </>)
            })
            }
            </div>
            <div style={{position: "sticky", top: 0, left: 0,marginTop: "20px"}}>
            <button className="add-question" onClick={handleAddClick}>Lägg till fråga +</button>
            <button onClick={() => uploadQuestions(questionsFromUser)} className="primary">Spara</button>
            </div>
        </div>
    </>
    )
}