import { useState } from "react";
import cls from "classnames";
import "./SwitchingCards.css";
import { useEffect } from "react";


const topContent = ['Hade du gjort detta?', 'Detta är mer top content.', 'Ännu mer top content.', 'Jag gillar banan.', 'Hejhopp.', 'Detta är sista toppfrågan.'];
const bottomContent = ['Eller sagt detta?', 'Detta är också bottom content.', 'Forfarande bottom content.', 'Hoppa över en lyktstolpe.', 'Hipp hej.', 'Detta är sista bottenfrågan.'];

export function SwitchingCardView({players, playerIndex, setNextPlayer, setPlayerModalOpen, onFinished}) {
  const [topContentIndex, setTopContentIndex] = useState(0);
  const [bottomContentIndex, setBottomContentIndex] = useState(0);

  useEffect(()=> {
    if (topContentIndex == topContent.length -1 && bottomContentIndex == bottomContent.length -1){
        onFinished();
    }
  },[topContentIndex, bottomContentIndex])


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 18 }}>
        <p id="player">{players[playerIndex]}</p>
      <SwitchingCard
        content={topContent[topContentIndex]}
        nextContent={topContent[topContentIndex + 1]}
        setNextPlayer={setNextPlayer}
        players={players}
        setPlayerModalOpen={setPlayerModalOpen}
        onFinished={onFinished}
        onSwitchFinish={() => setTopContentIndex(index => index + 1)} />

      <SwitchingCard
        content={bottomContent[bottomContentIndex]}
        nextContent={bottomContent[bottomContentIndex + 1]}
        setNextPlayer={setNextPlayer}
        players={players}
        setPlayerModalOpen={setPlayerModalOpen}
        onFinished={onFinished}
        onSwitchFinish={() => setBottomContentIndex(index => index + 1)} />
    </div>
  )
}



function SwitchingCard({ content, nextContent, onSwitchFinish, setNextPlayer, setPlayerModalOpen, players, onFinished }) {
  const [isAnimationRunning, setAnimationRunning] = useState(false);

  return (
    <div
      className={cls({
        "switching-card": true,
        "switching": isAnimationRunning && nextContent,
        "no-next-content": isAnimationRunning && !nextContent
      })}
      onClick={() => {
            if (players.length == 0) {
                setPlayerModalOpen(true);
            } else {
                setAnimationRunning(true); 
                if (nextContent) {
                    setNextPlayer();
                }
            }
          }}>
      <div className="switching-card-content" onAnimationEnd={() => {
        setAnimationRunning(false);

        if (isAnimationRunning && nextContent) {
          onSwitchFinish();
        }
      }}>
        {content}
      </div>
      <div className="switching-card-content next">
        {nextContent}
      </div>
    </div>
  )
}