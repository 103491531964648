import './pointinggame.css';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRandomPlayer } from '../../utils.js';
import { db, getQuestions } from '../../App';
import fallbackQuestions from './questionsCache.json'
import { doc, getDoc } from 'firebase/firestore';
import { shuffle } from 'fast-shuffle';

//GÖR SÅ MED ALLA ^^^^^^^^^^^ utils

export function PointingGameView({ onFinished, getRandomNumber }) {
    const [questions, setQuestions] = useState([]);
    const [isGettingQuestions, setIsGettingQuestions] = useState(true);

    async function getQuestions(db) {
        try {
            const questionsCol = doc(db, 'Games', "Pekleken");
            const questionSnapshot = await getDoc(questionsCol);
            const questionList = shuffle(questionSnapshot.data().questions);
            setIsGettingQuestions(false)
            setQuestions(questionList);
        } catch (e) {
            console.error(e);
            setQuestions(shuffle(fallbackQuestions));
        }

    }

    useEffect(() => {
        getQuestions(db);
    }, [])

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);

    function setNextQuestion() {
        if (questions.length > currentQuestionIndex) {
            setCurrentQuestionIndex(currentQuestionIndex => currentQuestionIndex + 1);
        } else {
            //  Inga frågor kvar
            onFinished();
        };
    }

    const hasCurrentQuestion = !!questions[currentQuestionIndex] && !isGettingQuestions;

    return (
        <div id="question-game-container">
            <div id="header">
                <h1>Pekleken</h1>
            </div>
            <div id="question">
                <p style={{transition:"opacity 0.5s", opacity: isGettingQuestions ? 0 : 1 }}>{hasCurrentQuestion ? questions[currentQuestionIndex] : <>Klicka på knappen<br></br> för att starta.<br></br><span className='explainer'>Peka upp när du är redo att peka, när alla pekar upp<br></br> - räkna ner och peka samtidigt.</span></>}</p>
            </div>
            <button className="primary" onClick={() => setNextQuestion()}>Nästa</button>
        </div>
    )
}