import { Link } from "react-router-dom"


export function SelectGameView({ games , players, setPlayerModalOpen}) {

    return (
        <div id="game-select-container">
        {games.map((game, i) => {
            return (<>
                <Link className="game-link" onClick={()=> {if(!players[0] && game.slug != "pointing-game") setPlayerModalOpen(true)}} to={game.slug}>
                    <div className="game-option">
                        <h2 className="game-name">{game.name}</h2>
                        <img className="game-icon" src={game.icon}></img>
                    </div>
                </Link>
            </>)
})}

</div>)

}